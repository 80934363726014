﻿/*
 *  Document   : _extra_pages.scss
 *  Author     : RedStar Theme
 *  Description: This scss file for extra pages like sign in, sign up etc.. style classes
 */

.txt1 {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 1.4;
  color: #555555;

  &:hover {
    text-decoration: underline;
  }
}
.txt2 {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}
.size1 {
  width: 355px;
  max-width: 100%;
}
.size2 {
  width: calc(100% - 43px);
}
.bg1 {
  background: #3b5998;
}
.bg2 {
  background: #1da1f2;
}
.bg3 {
  background: #cd201f;
}
.limiter {
  width: 100%;
  margin: 0 auto;
}
.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 30px;
}
.wrap-login100 {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}
.login100-more {
  width: calc(100% - 560px);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  z-index: 1;
}
.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.login100-form {
  width: 453px;
  display: inline-table;
  background-color: #ffffff;
  padding: 34px 55px;
  margin: 30px;
  border: 1px solid #cacaca;
  border-radius: 20px;
}
.login100-form-title {
  font-size: 30px;
  font-family: Poppins, sans-serif;
  color: #403866;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
}
.error-header {
  font-size: 80px;
  font-family: Poppins, sans-serif;
  color: #403866;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
}
.error-subheader {
  font-size: 17px;
  font-family: Poppins, sans-serif;
  color: #403866;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
}
.error-subheader2 {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  color: #919192;
  text-align: center;
  width: 100%;
  display: block;
  font-weight: bold;
}
.wrap-input100 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 60px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 10px;
}
.label-input100 {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  color: #999999;
  line-height: 1.2;
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 24px;
  left: 0;
  top: 21px;
  transition: all 0.4s;
}
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 16px !important;
  &:focus {
    height: 48px;
    box-shadow: none !important;
    + .focus-input100 {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
      + .label-input100 {
        top: 7px;
        font-size: 13px;
      }
    }
  }
}
.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #6675df;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  transform: scaleX(1.1) scaleY(1.3);
}
.eff-focus-selection {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.has-val {
  height: 48px !important;
  + .focus-input100 + .label-input100 {
    top: 7px;
    font-size: 13px;
  }
}
.input-checkbox100 {
  display: none;
  &:checked + .label-checkbox100::before {
    color: #6675df;
  }
}
.label-checkbox100 {
  font-family: Poppins, sans-serif;
  font-size: 13px;
  color: #999999;
  line-height: 1.4;
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}
.label-checkbox100::before {
  content: "\f007";
  font-family: Font awesome\5 Free;
  font-size: 13px;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background: #fff;
  border: 1px solid #6675df;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #6675df;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background: #333333;
  }
}
@media (max-width: 992px) {
  .login100-form {
    width: 100%;
    display: block;
    padding-left: 30px;
    padding-right: 30px;
  }
  .login100-more {
    width: 50%;
  }
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .login100-form {
    width: 100%;
    display: block;
  }
  .login100-more {
    display: none;
  }
}
@media (max-width: 576px) {
  .login100-form {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 70px;
  }
}
.validate-input {
  position: relative;
  .erroe_dis {
    display: none;
  }
}
.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  z-index: 100;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  pointer-events: none;
  font-family: Poppins, sans-serif;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
}
.alert-validate::after {
  content: "\f12a";
  font-family: Font awesome\5 Free;
  display: block;
  position: absolute;
  z-index: 110;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  font-weight: 600;
  transform: translateY(-50%);
  right: 18px;
}
.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
  &:hover {
    background: #333333;
    color: #fff;
  }
}
.flex-w {
  display: flex;
  flex-wrap: wrap;
}
.flex-l {
  display: flex;
  justify-content: flex-start;
}
.flex-r {
  display: flex;
  justify-content: flex-end;
}
.flex-c {
  display: flex;
  justify-content: center;
}
.flex-sa {
  display: flex;
  justify-content: space-around;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
}
.flex-t {
  display: flex;
  align-items: flex-start;
}
.flex-b {
  display: flex;
  align-items: flex-end;
}
.flex-m {
  display: flex;
  align-items: center;
}
.flex-str {
  display: flex;
  align-items: stretch;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-rev {
  display: flex;
  flex-direction: row-reverse;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-col-rev {
  display: flex;
  flex-direction: column-reverse;
}
.flex-c-m {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-c-t {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-c-b {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.flex-c-str {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.flex-l-m {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-r-m {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-sa-m {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-sb-m {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
input.input100 {
  height: 100%;
  transition: all 0.4s;
  margin-bottom: 0 !important;
  font-size: 14px !important;
  padding-left: 25px !important;
  padding-top: 14px !important;
  border-bottom: none !important;
}
.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

// login register
.loginmain {
  background: #f1f2f7;
}
.site-link {
  padding: 5px 15px;
  position: fixed;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  right: 30px;
  bottom: 30px;
  border-radius: 10px;
  img {
    width: 30px;
    height: 30px;
  }
}
.loginCard {
  width: 70%;
  margin: 10% auto;
  // background-image: url("../../../assets/images/bg-01.jpg");
  min-height: 400px;
  position: relative;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  > div {
    text-align: center;
    color: #fff;
    width: 50%;
    left: 0;
    top: 40%;
    position: absolute;
  }
  .rgstr-btn {
    float: right;
    right: 0;
    left: auto;
  }
  button {
    background: transparent;
    display: inline-block;
    padding: 10px 30px;
    border: 3px solid #fff;
    border-radius: 50px;
    background-clip: padding-box;
    position: relative;
    color: #fff;
    transition: all 0.25s;
    &.dark {
      border-color: #bd59d4;
      background: #bd59d4;
      box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -moz-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -webkit-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -o-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
      -ms-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    }
    &:active {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
  .wrapper {
    > form {
      padding: 15px 30px 30px;
      transition: all 0.5s;
      background: #fff;
      width: 100%;
      border-radius: 20px;
      &:focus {
        outline: none;
      }
      > div {
        position: relative;
        margin-bottom: 15px;
      }
    }
    #login {
      padding-top: 20%;
      visibility: visible;
    }
    #register {
      transform: translateY(-80%) translateX(100%);
      visibility: hidden;
    }
    &.move {
      #register {
        transform: translateY(-100%) translateX(0%);
        visibility: visible;
      }
      #login {
        transform: translateX(-100%);
        visibility: hidden;
      }
    }
    label {
      position: absolute;
      top: -7px;
      font-size: 12px;
      white-space: nowrap;
      background: #fff;
      text-align: left;
      left: 15px;
      padding: 0 5px;
      color: #999;
      pointer-events: none;
    }
    h3 {
      margin-bottom: 25px;
    }
    input {
      height: 40px;
      width: 100%;
      border: solid 1px #999;
      border-radius: 20px;
      padding-left: 10px;
      &:focus {
        outline: none;
        border-color: #ff4931;
        box-shadow: none !important;
        border-bottom: 1px solid #ff4931 !important;
      }
    }
  }
  .move button.dark {
    border-color: #bd59d4;
    background: #bd59d4;
    box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(189, 89, 212, 0.5);
  }
  .splits p {
    font-size: 18px;
  }
  > .wrapper {
    position: absolute;
    width: 40%;
    height: 120%;
    top: -10%;
    left: 5%;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    transition: all 0.5s;
    color: #303030;
    overflow: hidden;
    &.move {
      left: 45%;
    }
  }
}
@media (max-width: 767px) {
  .loginCard {
    padding: 5px;
    margin: 0;
    width: 100%;
    display: block;
    > div {
      display: inline-block;
    }
    > .wrapper {
      position: relative;
      height: auto;
      top: 0;
      left: 0;
      width: 100%;
      &.move {
        left: 0;
      }
    }
  }
  .splits {
    width: 50%;
    background: #fff;
    float: left;
    button {
      width: 100%;
      border-radius: 0;
      background: #505050;
      border: 0;
      opacity: 0.7;
      &.active {
        opacity: 1;
        background: #ff4931;
      }
    }
    p {
      display: none;
    }
    &.rgstr-btn button.active {
      background: #e0b722;
    }
  }
}
input:-webkit-autofill {
  box-shadow: inset 0 0 0 50px #fff;
}
textarea:-webkit-autofill {
  box-shadow: inset 0 0 0 50px #fff;
}
select:-webkit-autofill {
  box-shadow: inset 0 0 0 50px #fff;
}
.flex-c {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.login100-social-item {
  font-size: 25px;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin: 5px;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
}
.login100-form-logo {
  font-size: 60px;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: transparent;
  margin: 0 auto;
  img {
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
}
.bg-fb {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #1da1f2;
}
.bg-google {
  background-color: #ea4335;
}
.lbl-alert {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 12px 12px;
  border: 1px dashed #bfc9d4;
  border-radius: 6px;
}
.show-pwd-icon {
  color: #000000;
}
