﻿/*
 *  Document   : _dashboard.scss
 *  Author     : RedStar Template
 *  Description: This scss file for dashboard page style classes
 */
.dashboard-flot-chart {
  height: 275px;
}

.dashboard-donut-chart {
  height: 265px;
  text-align: center;
}

.dashboard-line-chart {
  height: 250px;
}

.dashboard-stat-list {
  list-style: none;
  padding-left: 0;
  margin-top: 40px;

  li {
    padding: 16px 0 0 0;

    small {
      font-size: 8px;
    }
  }
}

.dashboard-task-infos {
  .progress {
    height: 10px;
    position: relative;
    top: 6px;
  }
}
.totalEarning {
  text-align: center;
  padding: 6px;
  color: #ff9800;
}
.earningProgress .progress {
  height: 9px;
}
.text-4 {
  color: rgb(222 222 222);
  // margin-left: 35px;
}

.widget-small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.widget-small .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 85px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
  font-size: 2.5rem;
}

.widget-small .info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 20px;
  -ms-flex-item-align: center;
  align-self: center;
}

.widget-small .info h4 {
  text-transform: uppercase;
  // margin-top: 20px;
  // margin-bottom: 0px;
  font-weight: 400;
  font-size: 1.1rem;
  margin: 20px 0px 0px 0px;
  text-align: right;
}

.widget-small .info p {
  margin: 5px;
  font-size: 33px;
}

.widget-small.primary {
  // background-color: #009688;
}

.widget-small.primary.coloured-icon {
  // background-color: var(--dark-black);
  // background-color: #fff;
  color: #fff;
  // color: #fff;
  // color: rgb(222 222 222);
  // border: 1px solid #ef6f35;
  // border: 1px solid darkorange;
  filter: drop-shadow(0 0 2px #f85106);
  // box-shadow: inset 0 0 2px #ffffff66;
  box-shadow: inset 0 0 2px #00000066;
  // height: 101px;
}

.widget-small.primary.coloured-icon .icon {
  background-color: #009688;
  color: #fff;
}

.widget-small.info {
  background-color: #17a2b8;
}

.widget-small.info.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.info.coloured-icon .icon {
  background-color: #17a2b8;
  color: #fff;
}

.widget-small.warning {
  background-color: #ffc107;
}

.widget-small.warning.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.warning.coloured-icon .icon {
  background-color: #ffc107;
  color: #fff;
}

.widget-small.danger {
  background-color: #dc3545;
}

.widget-small.danger.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.danger.coloured-icon .icon {
  background-color: #dc3545;
  color: #fff;
}
.widget-small {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
