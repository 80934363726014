/*
 *  Document   : _variables.scss
 *  Author     : RedStar Template
 *  Description: This scss file for declare variable for colors
 */
$colors: (
  "red": #f44336,
  "pink": #e91e63,
  "purple": #9c27b0,
  "indigo": #3f51b5,
  "blue": #2196f3,
  "cyan": #00bcd4,
  "teal": #009688,
  "green": #28c76f,
  "yellow": #ffe821,
  "orange": #ff9800,
  "deep-orange": #ff5722,
  "brown": #795548,
  "grey": #9e9e9e,
  "black": #000000,
  "white": #ffffff,
  "theme-green": #769847,
) !default;

$linear-colors: (
  "green": linear-gradient(45deg, #04ff00, #349e08),
  "orange": linear-gradient(135deg, #ff8900, #c7460f),
  "cyan": linear-gradient(45deg, #72c2ff, #86f0ff),
  "red": linear-gradient(316deg, #fc5286, #fbaaa2),
  "purple": linear-gradient(230deg, #759bff, #843cf6),
  "purple-dark": linear-gradient(45deg, #a52dd8, #e29bf1),
  "card1": linear-gradient(to left, #3a7bd5, #3a6073),
  "card2": linear-gradient(to right, #c33764, #1d2671),
  "card3": linear-gradient(to left, #134e5e, #71b280),
  "card4": linear-gradient(to left, #d38312, #a83279),
);

//Fonts Family
$navbar-font-family: "Poppins";
$sidebar-font-family: "Poppins";
