/*
 *  Document   : _expansion
 *  Author     : RedStar Template
 *  Description: This scss file for collapse style classes
 */

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.example-headers-align .mat-mdc-form-field+.mat-form-field {
  margin-left: 8px;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: var(--bs-white);
}