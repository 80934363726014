/* You can add global styles to this file, and also import other style files */

@import url(https://fonts.googleapis.com/css2?family=Asap:wght@500&display=swap);
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
// @include mat.all-component-themes($my-theme);
// @include mat.all-legacy-component-themes($my-theme);

@mixin link-style {
  text-decoration: none;
  color: #adacac;
}

:root {
  --theme-orange: rgb(239 111 53);
  --theme-grey: rgb(91 104 113);
  --dark-grey: rgb(112 112 112);
  --medium-grey: rgb(150 162 180);
  --light-grey: rgb(222 222 222);

  --secondary-btn-clr: rgb(161 170 176);
  --sidebar-menu-item: rgb(199 201 201);
  --white-theme-background: rgb(241 242 247);
  --darker-black-background: rgb(8 29 39);
  --dark-nav-bar: rgb(16 39 51); // body
  --dark-black: rgb(41 64 78); // header
  --dark-blue: rgb(28 51 64);
  --card-1-color: rgb(13 110 253);
  --card-2-color: rgb(138 43 226);
  --card-3-color: rgb(255 140 0);
  --black-color: rgb(0 0 0);
  --green-color: rgb(8 200 104);
  --fw-medium: 500;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(193, 193, 193, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #f26321;
  background-color: #ef6f35;
  -webkit-box-shadow: inset 0 0 6px rgba(193, 193, 193, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(193, 193, 193, 0.4);
}

// Custom CSS to Tags
html,
body {
  height: 100%;
  height: calc(100vh - 60px);
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  background-color: var(--white-theme-background);

  .z-index-1 {
    z-index: 1;
  }
}

a {
  cursor: pointer;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

// Theme default CSS
.theme-white-bg {
  background-color: var(--white-theme-background);
}

.text-orange {
  color: var(--theme-orange);
}

.text-grey {
  color: var(--theme-grey);
}

.text-secondary-clr {
  color: var(--secondary-btn-clr);

  &:hover {
    color: var(--bs-white);
  }
}

.angular-editor-textarea {
  color: #eaeaea;
}

section.content {
  padding-bottom: 3.5rem;
  margin-top: 60px;
  transition: 0.5s;
}

section.contents {
  padding-bottom: 3.5rem;
  margin-top: 145px !important;
  transition: 0.5s;
}

.font-size-12 {
  font-size: 0.75rem;
}

.mat-mdc-unelevated-button.mat-primary {
  color: rgb(255 255 255);
}

.font-20 {
  font-size: calc(0.8rem + ((1vw - 7.68px) * 0.5208));
  font-weight: 500;
}

.border-bottom-2 {
  border-bottom: 2px solid var(--dark-grey) !important;
}

// End Theme default CSS

// Material Custom CSS
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  background-color: var(--theme-orange);
}

.mat-basic {
  color: var(--theme-orange) !important;
}

.mat-expansion-panel {
  color: var(--medium-grey);
}

// Header CSS
app-header,
app-header-coach {
  .nav-header {
    background: var(--dark-black);
    color: var(--light-grey);

    .navbar-brand {
      img {
        margin-top: 0.6rem;
      }
    }

    .active {
      font-weight: bold;
    }

    .menu-link-container {
      margin: 0 1vw 0 auto;

      .navbar-links {
        cursor: pointer;
        padding: 6px 16px;

        &.active {
          color: var(--theme-orange);
        }
      }
    }
    .user_dw_menu {
      padding: 0;
    }
  }
}

// End Header CSS

// Dashboard Css
app-main {
  .text-with-icon-link {
    position: relative;
    top: -8px;
  }

  .widget-small {
    &.primary {
      &.coloured-icon {
        &.card-1 {
          filter: drop-shadow(0 0 2px var(--card-1-color));

          .icon {
            background-color: var(--card-1-color);
          }
        }

        &.card-2 {
          filter: drop-shadow(0 0 2px var(--card-2-color));

          .icon {
            background-color: var(--card-2-color);
          }
        }

        &.card-3 {
          filter: drop-shadow(0 0 2px var(--card-3-color));

          .icon {
            background-color: var(--card-3-color);
          }
        }
      }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      padding: 34px 20px;
    }

    .info {
      display: flex;
      justify-content: space-between;
      height: 100%;
      align-items: center;

      p {
        font-size: 2.75rem;
      }

      h4 {
        align-self: end;
        margin: 0 0 8px 0;
      }
    }
  }

  .mat-column-email {
    flex: 2 0 10px;
  }
}

// End Dashboard Css

// User List Css
app-coaches,
app-admins,
app-more {
  .card {
    .header {
      h2 {
        &.font-bold {
          font-weight: lighter;
        }
      }

      .filterTableHeader {
        padding: 10px 20px;
        align-items: center;
        border-color: #e6e9ed;
        background-color: #ebebee;

        .search-input {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.54);
          border: 0;
          height: 45px;
          // width: 250px;
          width: 100%;
          background: var(--bs-white);
          transition: background 0.2s, width 0.2s;
          padding: 8px 8px 8px 16px !important;
          border-radius: 5px !important;
        }
      }
    }

    .tableBody {
      .table-img {
        min-width: 53px;
      }
    }
  }

  .mat-column-email {
    flex: 2 0 10px;
  }
}

.disable-after::after {
  display: none !important;
}

.theme-black .navbar {
  box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
}

#workout-chart {
  .mat-mdc-tab-header {
    border: orange;
  }
}

.admin-table-action-wrapper {
  min-width: 90px;
}

// log in css

.sessionchart {
  margin: 40px;
}

.popupHeading {
  font-size: 0.875rem;
  margin-left: 0px;
  margin-top: 0px;
}

.mat-mdc-dialog-container {
  padding: 0px !important;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

// login screen
.login-box {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  font-size: 0.875rem;

  .login-header {
    background-color: rgb(41 64 78 / 80%);
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-body {
    background-color: var(--bs-white) f;
    text-align: center;
    padding: 2em 0;

    .microsoft-login-btn {
      border: 1px solid rgba(209, 102, 15, 1) !important;
      background-color: rgba(231, 122, 19, 1) !important;
      margin: 20px auto !important;

      width: 80% !important;
      padding: 16px;
      text-transform: uppercase;
      font-weight: bold;
      color: var(--bs-white);
      box-shadow: 2px 3px 6px 1px rgb(0 0 0 / 20%);
    }

    .termcond {
      text-decoration: underline;
      color: #333333;
      font-weight: 600;
    }

    .info-text {
      padding: 1em;
      color: #181717;
      margin-bottom: 0;
    }
  }

  .login-footer {
    padding: 20px;
    text-align: center;
    color: var(--light-grey);
    background-color: rgb(41 64 78 / 80%);
    width: 100%;

    i {
      color: var(--light-grey);
    }
  }
}

.login-footer-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(47, 53, 58);
  height: 5%;
  color: var(--light-grey);
  font-size: 0.75rem;
  z-index: 99;

  span,
  a {
    cursor: pointer;
  }

  a {
    font-size: 0.75rem;
  }
}

.mat-body,
.mat-body-2,
.mat-typography {
  font: 400 1rem/1.4rem;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  font-size: 1rem;
  font-weight: 500;
}

.mat-mdc-outlined-button {
  padding: 0 8px !important;
}

.white {
  #app-data-mgt {
    .mat-mdc-tab-body {
      margin-top: 10px;
      border-radius: 4px;
      padding: 8px;

      .mat-expansion-panel {
        background-color: var(--white-theme-background);
        border-bottom: 1px solid rgb(0 0 0 / 12%);
        border-top: 1px solid rgb(0 0 0 / 12%);
        border-radius: 0;
        margin: 0;

        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description,
        .mat-expansion-indicator::after {
          color: var(--bs-white) !important;
          align-items: center;
        }

        .mat-expansion-panel-header-description {
          justify-content: end !important;
        }
      }
    }
  }

  #FAQ {
    .mat-mdc-tab-header {
      border: orange;
    }
  }

  .mini-icon-btn {
    line-height: 14px;
    height: 14px;
    width: 14px;

    &.mat-mdc-icon-button i,
    &.mat-icon-button .mat-icon {
      line-height: 14px;
      height: 14px;
      width: 14px;
      font-size: 0.875rem;
    }
  }

  .add-button-wrapper {
    position: absolute;
    top: 18px;
    right: 8px;
    z-index: 1;

    .mat-mdc-unelevated-button.mat-accent,
    .mat-mdc-raised-button.mat-accent,
    .mat-mdc-fab.mat-accent,
    .mat-mdc-mini-fab.mat-accent {
      background-color: var(--theme-orange);
    }
  }
}

.dialog-container-custom .mat-mdc-dialog-container {
  .update-modal-wrapper {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1;

    .update-modal {
      box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
        0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
      background: var(--bs-white);
      color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      box-sizing: border-box;
      padding: 12px;

      width: 200px;
      max-height: 200px;
      overflow-y: auto;

      &:after {
        content: " ";
        position: absolute;
        right: 12px;
        top: -8px;
        border-top: none;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid var(--bs-white);
        z-index: 1;
      }
    }
  }

  .device-container {
    background-color: var(--bs-white);
    padding: 12px;
  }
}

#firmwareform {
  th {
    background-color: #d4dce0;
    color: var(--theme-orange) !important;
  }

  td {
    color: #5b626b;
  }
}

.profilefield {
  margin-top: -23px;
}

.label-position {
  position: absolute;
  right: 21px;
  top: 2rem;

  .label-success {
    &.green {
      background-color: var(--green-color);
      border-radius: 5px;
    }
  }
}

.table {
  thead {
    tr {
      th {
        color: var(--theme-orange);
      }
    }
  }
}

td {
  .btn-tbl-edit,
  .btn-tbl-schedule {
    color: var(--bs-white);
  }
}

.text-4 {
  color: #212529;
}

app-userview {
  .user-info-wrapper {
  }

  .user-detail-view-wrapper {
    .mat-mdc-tab-body {
      min-height: calc(100vh - 138px);
      border-radius: 4px;
      padding: 8px;
      .mat-tab-body-content {
        overflow: hidden;

        .card {
          &.grey-theme-bg {
            border: 0;
          }
        }

        .title {
          color: var(--theme-orange);
        }

        .unit {
          font-weight: lighter;

          &.orange {
            color: var(--theme-orange);
          }
        }
      }
    }
  }
}

.addBtn {
  background-color: #1a202e30 !important;

  &:hover {
    background-color: #ff944f !important;
  }
}

.darkmood {
  margin-left: 100px;
}

.onoffswitch {
  position: relative;
  width: 76px;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 28px;
  padding: 0;
  line-height: 28px;
  border: 0px solid #e3e3e3;
  border-radius: 45px;
  background-color: #d8d4d4;
}

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 45px;
  margin: -8.5px;
  background: #e2e0e0;
  background-image: url(http://s5.picofile.com/file/8396607192/sun.png);
  transition: 0.8s;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  border-radius: 45px;
  box-shadow: 0 6px 12px 0px #757575;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #2c3159;
}

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: black;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
  background-image: url(http://s4.picofile.com/file/8396607276/moon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #2d325a;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
}

.info {
  span:nth-child(2) {
    float: right;
  }

  span {
    a {
      @include link-style();

      &:hover {
        text-decoration: underline;
        color: #f64c91;
      }
    }
  }
}

.mat-mdc-slide-toggle {
  display: inline-block;
  height: 24px;
  max-width: 100%;
  line-height: 24px;
  white-space: nowrap;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  margin-right: 1%;
}

.mat-mdc-tab-header {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin-top: 16px !important;
}

.full-height-wrapper {
  min-height: calc(100vh - 154px);
}

// Mat Table Custom CSS

mat-row,
mat-header-row,
mat-footer-row {
  border-bottom-width: 0;
}

.mat-mdc-header-cell {
  color: var(--theme-orange);
  background-color: var(--dark-black);
  border: none;
  border-bottom: 10px solid var(--dark-nav-bar);
  font-weight: 500;
}

.mat-mdc-cell {
  background-color: var(--dark-blue);
  border-bottom: 5px solid var(--dark-nav-bar);
}

// Respiratory Detail Page
.sessionSummaryWrapper {
  background-color: var(--dark-black);

  .sessionSummaryList {
    display: flex;
    background-color: #081d27;
    padding: calc(1rem + ((1vw - 7.68px) * 0.6944));
    margin-bottom: 10px;
    cursor: pointer;

    .image-wrapper {
      background: var(--dark-black);
      position: relative;
      padding-top: 100%;
      overflow: hidden;
      height: 0;

      img {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      border: 2px solid var(--theme-orange);
    }
  }
}

button {
  &.mat-primary {
    &.export-btn {
      border-radius: 0;
      border-color: var(--theme-orange) !important;
      color: var(--theme-orange);
      background: #29404e !important;
    }
  }
}

// Respiratory & Session Screen CSS
.fix-height {
  height: 66px;
}

.list--sticky-top {
  position: sticky;
  top: 60px;
  background-color: var(--dark-nav-bar);
  z-index: 2;

  .height-63px {
    height: 50px;
  }

  &.top-144 {
    top: 144px;
  }
}

.search-list-container {
  background-color: var(--dark-black);
  padding: calc(0.875rem + ((1vw - 7.68px) * 0.5208));
  height: 100%;
  height: calc(100vh - 162px);
  overflow: auto;

  .search-list-wrapper {
    .user-list-header,
    .user-list {
      background-color: var(--darker-black-background);
      cursor: pointer;
      padding: calc(0.875rem + ((1vw - 7.68px) * 0.5208));
      margin-bottom: 10px;
    }

    .active {
      border: 2px solid var(--theme-orange);
    }
  }
}

.icon-wrapper {
  width: 50px !important;
  height: 50px;
  margin-right: calc(0.875rem + ((1vw - 7.68px) * 0.5208));
}

.mat-mdc-table .mat-mdc-header-cell {
  font-weight: 600;
}

.mat-mdc-header-cell {
  color: var(--theme-orange);
  background-color: var(--dark-black);
  margin-bottom: 0;
  border: none;
  border-bottom: 10px solid var(--dark-nav-bar);
  font-weight: 500;
}

.mat-mdc-cell {
  background-color: var(--dark-blue);
  border-bottom: 10px solid var(--dark-nav-bar);
}

// Tab button Css

.userFilter {
  background-color: #495c66 !important;
  color: var(--theme-orange) !important;

  &:hover {
    background-color: var(--theme-orange) !important;
    color: var(--bs-white) !important;
  }
}

.customClassForDisabledBtn {
  background-color: #495c66 !important;
  color: var(--theme-orange) !important;
}

.customClassForDisabledBtns {
  background-color: #495c66 !important;
  color: var(--theme-orange) !important;

  &:hover {
    background-color: var(--theme-orange) !important;
    color: var(--bs-white) !important;
  }
}

.tab-buttons {
  background-color: #081d27;
  color: var(--bs-white);
  border-radius: 6px;
  padding: 8px 20px;
  margin-right: 20px;
  border: none;
  font-weight: 100;

  &.active {
    border: 1px solid var(--theme-orange);
    font-weight: 500;
  }
}

.full-height-wrapper {
  min-height: calc(100vh - 79px);
}

// Setting css
.sidebar-menu-wrapper {
  background-color: var(--dark-black);
  height: calc(100vh - 162px);
  overflow: auto;
  padding: 0;

  ul {
    padding: 0;

    li {
      padding: calc(0.875rem + ((1vw - 7.68px) * 0.5208));

      a {
        color: var(--sidebar-menu-item);
        text-decoration: none;

        &.multi-level-dropdown {
          padding: calc(0.875rem + ((1vw - 7.68px) * 0.5208));
          border-bottom: 0px solid var(--dark-grey);
        }
      }

      ul {
        border-bottom: 2px solid var(--dark-grey);
        border-top: 2px solid var(--dark-grey);

        a {
          &.active {
            color: var(--theme-orange);
          }
        }
      }
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
}

.example-tooltip-bg {
  margin-top: -10px;
  background: var(--theme-orange) !important;
}

.card-header {
  padding: 1.5rem 1rem 1rem 1rem !important;
}

.text-right {
  text-align: right !important;
}

.session-title {
  font-size: 17px !important;
  margin-left: 1.4rem !important;
  height: 12px !important;
}

.toggle.mat-mdc-slide-toggle {
  margin-left: 0.2rem !important;
}

// sayali pharne css changes
.appDetails {
  color: #000 !important;
}

.requestText {
  font-size: 1.7rem !important;
  font-weight: 400 !important;
}

.popupHeader,
.wholePopup {
  background-color: #1d3947 !important;
}

.rejectText {
  font-size: 1.3rem !important;
  font-weight: 400 !important;
}

.userActivityCss {
  overflow-y: auto !important;
  height: 43% !important;
}

.nameCSS {
  color: #ef6f35 !important;
}

.userActionsDropdown {
  position: relative !important;
  max-width: 600px !important;
  max-height: 300px !important;
}

.bgWhite {
  color: white !important;
}

.graphHeight {
  height: calc(100vh - 270px) !important;
  overflow: auto !important;
}

.graphCss {
  overflow-y: auto !important;
  height: 43% !important;
  height: 50% !important;
  max-width: 100% !important;
}

.conSession {
  height: calc(100vh - 335px) !important;
}

.cusAlignClass {
  margin-top: -33px !important;
}

.consSession {
  overflow-y: auto !important;
}

.defGraph {
  overflow-y: auto !important;
  max-width: 100% !important;
}

.sessionSummary {
  overflow-y: auto !important;
  height: 424px !important;
}

.sessionSummaryCss {
  overflow-y: auto !important;
  height: 424px !important;
  max-width: 100% !important;
}

.editCss {
  font-size: 1.7rem !important;
  font-weight: 400 !important;
}

.footer {
  text-align: center;
  height: 6%;
  display: flex;
  align-items: center;
  background: #29404e;
  border-top: solid 0.2rem #ef6f35;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  color: #c7c9c9;
  box-shadow: 0px -1px 5px 0px #000;
  font-size: 1rem;
  width: 100%;
}

.customUserFilter mat-icon {
  color: var(--theme-orange);
}

.customUserFilter:hover {
  color: #fff;
  background-color: var(--theme-orange);
}

.customUserFilter mat-icon:hover {
  color: #fff;
}

::ng-deep .white-text-input.mat-mdc-input-element {
  color: white !important;
}

.msgAlignment {
  margin-left: -50.5rem !important;
}

.rowDivider {
  height: 9px !important;
  border-bottom: 1.5px solid #ec6e35 !important;
}

.customBodyCss {
  padding: 12px !important;
}

.infoIcon {
  margin-left: 5px !important;
}

.cusClass {
  pointer-events: none;
  opacity: 0.5;
}

// show password
.hide {
  display: none !important;
}

.tabSec {
  margin-top: 0px !important;
  transition: 0.5s;
}

.teamFilter {
  margin-right: 15px !important;
}

.teamFilters {
  margin: 1px 15px !important;
  width: 89px !important;
}

.ng-select.ngSelectDropdownCss {
  border: 0px;
  border-radius: 0;
  color: #fff !important;
  background-color: #1d3947 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #fff !important;
  background-color: #1a202e !important;
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: #1d3947;
  border-color: #707070 #707070 #707070;
  color: #fff !important;
}

.ng-select .ng-select-container {
  color: #fff !important;
  background-color: #1d3947;
  border-radius: 4px;
  border: 1px solid #707070;
  min-height: 37px;
  align-items: center;
}

.ng-dropdown-panel .scroll-host {
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  display: block;
  -webkit-overflow-scrolling: touch;
  max-height: 74px !important;
  color: #fff !important;
  background-color: #1d3947 !important;
}

::ng-deep .ng-dropdown-panel {
  background-color: #1d3947 !important;
  color: white !important;
}

::ng-deep .ng-dropdown-panel .ng-option {
  background-color: #1d3947 !important;
  color: white !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #1d3947 !important;
  color: #fff !important;
}

.bgContent {
  background-color: rgb(41 64 78);
  color: #fff !important;
}

.cssHelp {
  border: solid #ef6f35 1px;
  border-radius: 10px;
}

.actualContent {
  padding: 45px 35px;
  border-radius: 10px;
  max-height: 71vh;
  overflow: hidden;
  overflow-y: auto;
}

.actualContents {
  padding: 32px 35px;
  border-radius: 10px;
  max-height: 10vh;
}

.heightExpansionPanel {
  max-height: 76vh !important;
  overflow-y: auto !important;
}

.heightExpansionPanelMobile {
  max-height: 75vh !important;
  overflow-y: auto !important;
}

.text-colour {
  color: #f44336 !important;
  font-size: 75% !important;
  margin-left: 10px !important;
}

::ng-deep .ng-select-container.ng-invalid.ng-touched {
  border-color: #f44336 !important;
}

::ng-deep .ng-select.ng-invalid {
  border: 1px solid #f44336 !important;
}

.blankPage {
  app-footer,
  app-header {
    display: none !important;
  }

  .main-content {
    padding: 0 !important;
  }
}

.tabHeaderCss {
  background: var(--dark-black);
  height: 63px;
}

::ng-deep .teamCss {
  margin: 0 !important;
}

ng-select.ng-invalid.ng-submitted {
  border: 1px solid #f44336 !important;
}

.error-border {
  .ng-select-container {
    border: 2px solid#f44336 !important;
  }
}

::ng-deep .ng-select-placeholder {
  color: #fff;
}

.ng-select .ng-select-placeholder {
  color: #fff !important;
}

.logBtnCss {
  margin-right: 30px !important;
  margin-top: 2px !important;
}

.vertical-container {
  display: flex !important;
  flex-direction: column !important;
}

.defaultBtn {
  position: relative !important;
  margin-left: 16rem !important;
}

.defaultBtns {
  position: relative !important;
  margin-left: 13rem !important;
}

:host ::ng-deep.ng-dropdown-panel .ng-option.ng-option-selected {
  color: #fff !important;
  background-color: #fff !important;
}

:host ::ng-deep.alignUserList {
  margin-top: 17px !important;
}

.iconSize {
  font-size: 26px;
  margin-top: -3px;
}

.hoverImg {
  position: relative;
}

.helpSecCss {
  margin-top: 40px !important;
}

.leftMobileSec {
  margin-top: 17px !important;
}

.newClassSession {
  height: calc(100vh - 251px) !important;
}

.newClassSessions {
  height: calc(100vh - 290px) !important;
}

.adminHeightGraphs {
  margin-top: 60px !important;
}

.respGraphCss {
  margin-bottom: 74px !important;
}

.yearFont {
  font-size: 0.9rem !important;
}
.yearFontText {
  font-size: 0.8rem !important;
}

// 
.graphYear {
  margin-top: -14px !important;
}

.graphYears {
  margin-top: 8px !important;
}

.custom-ng-select .ng-clear-wrapper {
  display: none;
}

.custom-ng-select .ng-placeholder {
  display: block !important;
}
.custom-ng-selects .ng-placeholder {
  display: none !important;
}

.custom-ng-select.ng-select-focused .ng-clear-wrapper {
  display: block;
}

.custom-ng-select.ng-select-focused .ng-placeholder {
  display: none;
}

.responsiveLogo {
  display: none;
}

.normalScreenLogo {
  display: block;
}

// Submit/save btn
.primaryBtn {
  background-color: #ef6f35 !important;
  border: 1px #ef6f35 !important;
}

// log button
.logFileBtn {
  background-color: #ef6f35 !important;
  border: 1px #ef6f35 !important;
  color: #ffffff !important;
}

// // table css - start
.mat-mdc-header-row {
  background: #29404e !important;
}

.mdc-data-table__header-cell {
  font-family: var(
    --mdc-typography-subtitle2-font-family,
    var(--mdc-typography-font-family, Poppins, sans-serif)
  ) !important;
}

.mdc-data-table__header-cell {
  border-bottom-width: 10px !important;
  border-bottom-style: solid !important;
}

.mdc-data-table__cell {
  font-family: var(
    --mdc-typography-body2-font-family,
    var(--mdc-typography-font-family, Poppins, sans-serif)
  ) !important;
}

// change password css - start
:host :ng-deep.visibility {
  color: #96a2b4 !important;
  font-size: 21px !important;
}
// change password css - end

// text- area placeholder css change - start
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #bbb !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #bbb !important;
}

// // text- area placeholder css change - end

.viewPopup {
  padding: 10px !important;
}

// transition effect remove css start
::ng-deep app-add-request-modal {
  --mat-dialog-transition-duration: none !important;
}

::ng-deep
  .mat-mdc-dialog-container
  .mdc-dialog
  .cdk-dialog-container
  .mdc-dialog--open {
  --mat-dialog-transition-duration: none !important;
}

// // transition effect remove css end

// dropdown options css - start
.mdc-list-item__primary-text,
.defaultOption {
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 12px !important;
}

.mat-mdc-select-min-line {
  color: #ffffff !important;
}

:host::ng-deep .mat-pseudo-checkbox {
  display: none !important;
}
// dropdown options css - end

.activeUser {
  display: inline-block;
  height: 27px !important;
  width: 27px !important;
  line-height: 30px !important;
  text-align: center;
  font-size: 18px;
  color: #ff944f;
  border-radius: 50%;
  border: 2px solid #ff944f;
}

:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #707070 !important;
}

.disabledBtn {
  opacity: 0.5 !important;
}

// check sign css
.mat-pseudo-checkbox
  .mat-mdc-option-pseudo-checkbox
  .mat-pseudo-checkbox-checked
  .mat-pseudo-checkbox-minimal {
  display: none !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #707070;
}

::ng-deep .error-border {
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
    .mat-form-field-outline-thick {
    border: 2px solid#f44336 !important;
  }
}

.month-selector1,
.defaultOption {
  mat-pseudo-checkbox {
    display: none !important;
  }
}

.mat-mdc-paginator-icon {
  fill: #fff !important;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #fe6f35 !important;
}

::ng-deep .mat-mdc-tooltip .InfoIIcon {
  --mdc-plain-tooltip-container-color: #495c66 !important;
}

.mat-mdc-option {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  min-height: 2em !important;
}

.mat-datepicker-content .mat-calendar {
  width: 296px !important;
  height: 354px !important;
}

.mat-mdc-select-arrow {
  color: #b3b4b4 !important;
}

:ng-deep .mat-select-panel .mat-option:first-child {
  color: #b8bbbd !important;
  background: rgba(0, 0, 0, 0.4) !important;
}

.editWeb {
  position: relative !important;
  left: -7px !important;
  top: -5px !important;
}

.deleteWeb {
  position: relative !important;
  left: -9px !important;
  top: -5px !important;
}

.accordion-item {
  background-color: #29404e !important;
  border: 0px solid #29404e !important;
}

.setting-tab .card-header {
  padding: 1rem !important;
  background-color: #203543 !important;
}

.accordion-header {
  padding: 1rem !important;
  background-color: #203543 !important;
}

.accordion-button {
  background-color: #203543 !important;
  color: #fff !important;
  border: none !important;
}

.editFaqSec,
.deleteFaqIcon {
  position: relative !important;
  top: 4px !important;
  overflow: visible !important;
}

html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #757575 !important;
  --mdc-switch-selected-handle-color: #757575 !important;
  --mdc-switch-selected-hover-state-layer-color: #757575 !important;
  --mdc-switch-selected-pressed-state-layer-color: #757575 !important;
  --mdc-switch-selected-focus-handle-color: #212121 !important;
  --mdc-switch-selected-hover-handle-color: #212121 !important;
  --mdc-switch-selected-pressed-handle-color: #212121 !important;
  --mdc-switch-selected-focus-track-color: #e0e0e0 !important;
  --mdc-switch-selected-hover-track-color: #e0e0e0 !important;
  --mdc-switch-selected-pressed-track-color: #e0e0e0 !important;
  --mdc-switch-selected-track-color: #e0e0e0 !important;
}

:host ::ng-deep html .mat-mdc-slide-toggle:hover {
  --mdc-form-field-label-text-color: #757575 !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__icon {
  display: none !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__icon {
  display: none !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-color: #ffffff !important;

  &.snackbar-danger {
    --mdc-snackbar-container-color: #e73b3b !important;
  }

  &.snackbar-success {
    --mdc-snackbar-container-color: #1bb21c !important;
  }
}

.mat-mdc-tooltip .custom-tooltip-css {
  background-color: #495c66 !important;
}

::ng-deep .mat-mdc-button:not(:disabled) .primaryBtn {
  color: #b8bbbd !important;
}

/* Target the 'error-border' class directly */
.error-border
  .mat-form-field-appearance-outline.mat-form-field-invalid
  .mat-form-field-outline-thick {
  border: 2px solid #f44336 !important;
}

/* Alternatively, target the input element within the invalid form field */
.mat-form-field.invalid .mat-input-element {
  border: 2px solid #f44336 !important;
}

.inputText {
  font-size: 14px !important;
}

.mat-form-field .mat-form-field-infix {
  height: 6px !important;
}

.mat-mdc-form-field-infix {
  min-height: 36px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 6px 0 !important;
}

.chart-form-controls.compact {
  height: 36px !important;
}

.mat-mdc-button:not(:disabled),
.mat-mdc-raised-button:not(:disabled) {
  color: #000 !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ),
.mat-mdc-option-active {
  background-color: #0b0d13 !important;
}

.dateField {
  margin-top: -4px !important;
}

::ng-deep input:hover {
  border-color: #707070 !important;
}

.hovering .mat-form-field-outline:hover {
  border-color: #707070 !important;
}

.mdc-text-field__input {
  font-size: 14px !important;
  line-height: 28px !important;
}

::ng-deep .saveBtn {
  color: #fff !important;
}
::ng-deep .cancelBtn {
  color: #000000 !important;
}

.phoneFieldWidth {
  width: 99% !important;
}

.roleFieldWidth {
  width: 97% !important;
}

.nameCss {
  font-size: 14px !important;
}

.regularLayout {
  display: flex !important;
  align-items: center !important;
  justify-content: between !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: #707070 !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #707070 !important;
}

.sessionTxt {
  margin-left: -22rem !important;
}

.graphTxt {
  margin-right: 18rem !important;
}

::ng-deep .mat-mdc-snack-bar-container .mdc-snackbar__label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  text-align: center !important;
}

::ng-deep.device-container {
  background-color: #1d3947 !important;
  padding: 1rem !important;
}

@media (min-width: 1440px) {
  .tableFont {
    font-size: 12px !important;
  }
  .mat-mdc-header-cell.nameWidth {
    min-width: 12rem !important;
  }

  .mat-mdc-cell.nameWidth {
    min-width: 12rem !important;
  }

  .mat-mdc-header-cell.sizeWidth {
    min-width: 5rem !important;
  }

  .mat-mdc-cell.sizeWidth {
    min-width: 5rem !important;
  }
  .mat-mdc-header-cell.versionWidth {
    min-width: 5rem !important;
  }

  .mat-mdc-cell.versionWidth {
    min-width: 5rem !important;
  }
  .mat-mdc-header-cell.statusWidth {
    min-width: 5rem !important;
  }

  .mat-mdc-cell.statusWidth {
    min-width: 5rem !important;
  }
  .mat-mdc-header-cell.uploadedDateWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.uploadedDateWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.uploadedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.uploadedByWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.publishedWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.publishedWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.publishedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.publishedByWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.BackWordWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.BackWordWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.actionWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.actionWidth {
    min-width: 7rem !important;
  }

  .tableFont {
    font-size: 12px !important;
  }
}

@media (max-width: 1440px) {
  .caloriesClass {
    text-align: center !important;
  }

  .customPaddingForSessionDate {
    padding-right: 7.5rem !important;
  }
}

@media (min-width: 1400px) {
  .col-xxl-2-5 {
    flex: 0 0 auto;
    width: 20% !important;
  }

  .col-xxl-7-5 {
    flex: 0 0 auto;
    width: 80% !important;
  }
}

@media (max-width: 1400px) {
  .tab-buttons {
    background-color: #081d27;
    color: var(--bs-white);
    border-radius: 6px;
    padding: 8px 10px !important;
    margin-right: 20px;
    border: none;
    font-weight: 100;
  }

  .firmTable {
    position: relative !important;
    left: 27rem !important;
  }
}

@media (max-width: 1200px) {
  .mat-mdc-header-cell.fNameWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.fNameWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.lNameWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.lNameWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.emailWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-cell.emailWidth {
    min-width: 15rem !important;
  }
  .mat-mdc-header-cell.phoneWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.phoneWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-header-cell.dateWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.dateWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-header-cell.deviceDetailWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.deviceDetailWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-header-cell.memberActionWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-cell.memberActionWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-header-cell.statusWidth {
    min-width: 8rem !important;
  }

  .mat-mdc-cell.statusWidth {
    min-width: 8rem !important;
  }
  .mat-mdc-header-cell.approvedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.approvedByWidth {
    min-width: 10rem !important;
  }
}

@media (max-width: 1080px) {
  .firstNameClassForOnlyResponsive {
    margin-top: 10px !important;
  }

  .mat-mdc-header-cell.nameWidth {
    min-width: 12rem !important;
  }

  .mat-mdc-cell.nameWidth {
    min-width: 12rem !important;
  }

  .mat-mdc-header-cell.sizeWidth {
    min-width: 5rem !important;
  }

  .mat-mdc-cell.sizeWidth {
    min-width: 5rem !important;
  }
  .mat-mdc-header-cell.versionWidth {
    min-width: 5rem !important;
  }

  .mat-mdc-cell.versionWidth {
    min-width: 5rem !important;
  }
  .mat-mdc-header-cell.statusWidth {
    min-width: 5rem !important;
  }

  .mat-mdc-cell.statusWidth {
    min-width: 5rem !important;
  }
  .mat-mdc-header-cell.uploadedDateWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.uploadedDateWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.uploadedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.uploadedByWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.publishedWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.publishedWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.publishedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.publishedByWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.BackWordWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.BackWordWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.actionWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.actionWidth {
    min-width: 7rem !important;
  }

  .requestTitleCss {
    width: 6rem;
  }
}

@media (max-width: 1024px) {
  .deviceLabelsClass {
    font-size: 12px;
  }
  .valuesOfDevices {
    font-size: 12px;
  }
  .font-20 {
    font-size: calc(1rem + ((1vw - 7.68px) * 0.5208));
  }

  .bottomAverageDeviceUsageGraph {
    margin-bottom: 2.5rem !important;
  }

  .mat-mdc-header-cell.fNameWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.fNameWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.lNameWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.lNameWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.emailWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-cell.emailWidth {
    min-width: 15rem !important;
  }
  .mat-mdc-header-cell.phoneWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.phoneWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-header-cell.dateWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.dateWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.teamWidth {
    min-width: 14rem !important;
  }

  .mat-mdc-cell.teamWidth {
    min-width: 14rem !important;
  }

  .mat-mdc-header-cell.deviceDetailWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.deviceDetailWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-header-cell.memberActionWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-cell.memberActionWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-header-cell.statusWidth {
    min-width: 8rem !important;
  }

  .mat-mdc-cell.statusWidth {
    min-width: 8rem !important;
  }
  .mat-mdc-header-cell.approvedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.approvedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-header-cell.coachActionWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.coachActionWidth {
    min-width: 7rem !important;
  }
  .mat-mdc-header-cell.uploadedDateWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.uploadedDateWidth {
    min-width: 7rem !important;
  }
  .mat-mdc-header-cell.mDateWidth {
    min-width: 12rem !important;
  }

  .mat-mdc-cell.mDateWidth {
    min-width: 12rem !important;
  }

  .generalTitle {
    font-size: 18px !important;
  }

  .tab-buttons {
    background-color: #081d27;
    color: var(--bs-white);
    border-radius: 6px;
    padding: 8px 10px !important;
    margin-right: 18px;
    border: none;
    font-weight: 100;
  }

  .m-l-30 {
    margin-left: 0px !important;
  }

  .msgAlignment {
    margin-left: -5.5rem !important;
  }

  .infoIcon {
    margin-left: 3px !important;
  }

  app-header .nav-header .menu-link-container,
  app-header-coach .nav-header .menu-link-container {
    margin: 0vw;
  }

  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    height: 63rem !important;
  }
}

@media (max-width: 992px) {
  .tab-buttons {
    background-color: #081d27;
    color: var(--bs-white);
    border-radius: 6px;
    padding: 8px 10px !important;
    margin-right: 14px;
    border: none;
    font-weight: 100;
  }

  .m-l-30 {
    margin-left: 0px !important;
  }
}

@media (max-width: 912px) {
  .mat-mdc-cell.actionWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.actionWidth {
    min-width: 9rem !important;
  }

  .tab-buttons {
    background-color: #081d27;
    color: var(--bs-white);
    border-radius: 6px;
    padding: 8px 10px !important;
    margin-right: 14px;
    border: none;
    font-weight: 100;
  }

  .tcBtn,
  .fcBtn {
    margin-top: 10px !important;
  }

  .msgAlignment {
    margin-left: 0.5rem !important;
  }
}

@media (max-width: 820px) {
  .mat-mdc-tab-header {
    max-width: 100%;
  }

  .mat-mdc-header-cell.deviceWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.deviceWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.macIdWidth {
    min-width: 8rem !important;
  }

  .mat-mdc-cell.macIdWidth {
    min-width: 8rem !important;
  }

  .responsiveLogo {
    display: block;
  }

  .normalScreenLogo {
    display: none;
  }

  .msgAlignment {
    margin-left: 5.5rem !important;
  }

  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    height: 55rem !important;
  }
}

@media (max-width: 768px) {
  .mat-mdc-tab-header {
    max-width: 100%;
  }

  .bottomAverageDeviceUsageGraph {
    margin-bottom: 0.5rem !important;
  }

  .mat-mdc-header-cell.fNameWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.fNameWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.lNameWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.lNameWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.emailWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-cell.emailWidth {
    min-width: 15rem !important;
  }
  .mat-mdc-header-cell.phoneWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.phoneWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.dateWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.dateWidth {
    min-width: 10rem !important;
  }
  .mat-mdc-header-cell.statusWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.statusWidth {
    min-width: 7rem !important;
  }
  .mat-mdc-header-cell.actionWidth {
    min-width: 9rem !important;
  }

  .mat-mdc-cell.actionWidth {
    min-width: 9rem !important;
  }
  .mat-mdc-header-cell.approvedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-cell.approvedByWidth {
    min-width: 10rem !important;
  }

  .mat-mdc-header-cell.coachActionWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.coachActionWidth {
    min-width: 7rem !important;
  }
  .mat-mdc-header-cell.deviceDetailWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-cell.deviceDetailWidth {
    min-width: 7rem !important;
  }

  .mat-mdc-header-cell.memberActionWidth {
    min-width: 15rem !important;
  }

  .mat-mdc-cell.memberActionWidth {
    min-width: 15rem !important;
  }

  .m-l-30 {
    margin-left: 0px !important;
  }

  .tcBtn,
  .fcBtn {
    margin-top: 10px !important;
  }

  .teamFilters {
    margin: -6px 15px !important;
  }

  .responsiveLogo {
    display: block;
  }

  .normalScreenLogo {
    display: none;
  }

  .msgAlignment {
    margin-left: 8.5rem !important;
  }

  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    height: 48rem !important;
  }

  .ipadClass {
    display: block !important;
  }

  .respiratoryTxt {
    position: relative !important;
    top: -12px !important;
  }

  .logButton {
    position: relative !important;
    top: -35px !important;
    right: 96px !important;
  }

  .requestTitleCss {
    width: 12rem;
  }

  .firstNameClassForOnlyResponsive {
    margin-top: 10px !important;
  }
}
